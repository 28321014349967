import React from "react"
import { FormattedMessage, useIntl } from "gatsby-plugin-intl"
import Layout from "../components/layout"
import SEO from "../components/seo"
import startImg from '../images/start-web.jpg';
import indexStyles from "./index.module.css"


const IndexPage = () => {

  const intl = useIntl()
  return (
    <Layout isStart>
      <SEO
        lang={intl.locale}
        title={intl.formatMessage({ id: "subtitle" })}
        keywords={[intl.formatMessage({ id: "meta.keyword1" }), intl.formatMessage({ id: "meta.keyword2" }), intl.formatMessage({ id: "meta.keyword3" })]}
        description={intl.formatMessage({ id: "meta.description" })}
      />

      <div className={indexStyles.container}>
        <img src={startImg} alt="Trascendemos" />
        <div className={indexStyles.middle}>
          <div className={indexStyles.text}>
          &bdquo;<FormattedMessage id="start.quote" />&rdquo;
          </div>
          <div className={indexStyles.name}>
            <FormattedMessage id="start.name" />
          </div>
        </div>
      </div>

    </Layout>
  )
}

export default IndexPage
